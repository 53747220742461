import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { GenkiLogo, RightArrowIcon, ExploreIcon, TrustPilotStarIcon, GenkiUnderline, GenkiMobileUnderline,TrustPilotIcon } from "../assets/icon"
import { Helmet } from "react-helmet"
import ButtonComponent from "../components/Button"
import "../style/landing.scss"
const LandingPage = () => {
    const [width, setWidth] = useState(1000)
    useEffect(() => {
        if (typeof window === "undefined") return
        const handleResize = () => setWidth(window.innerWidth)
        if (width === 1000) {
            handleResize()
        }
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    })
    if (width < 641) {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Genki</title>
                    <meta name="description" content="Genki the best nomad insurance, stay Genki" />
                </Helmet>
                <div className="mobile-container">
                    <div className="mobile-header-container">
                        <div className="mobile-genki-logo">
                            <GenkiLogo />
                        </div>
                        <ButtonComponent size="small" isDisabled icon={ExploreIcon} label="Explore" />
                    </div>
                    <div className="mobile-homepage-image">
                        <StaticImage alt="on TrustPilot" src="../assets/images/Homepage-mobile.png"  className="mobile-homepage-image"/>
                    </div>
                    <div className="mobile-text-container">
                        <div className="mobile-text-title-container">
                            <h1 className="mobile-text-title">Health insurance for nomads</h1>
                            <div className="mobile-text-title-underline">
                                <GenkiMobileUnderline />
                            </div>
                        </div>
                        <ButtonComponent label="Check your price" size="large" />
                        <div className="mobile-trustpilot-container">
                            <span className="body-content-trustpilot-container-inner-text ">Travelers love us</span>
                            <div className="body-content-trustpilot-container-inner-ratings-container">
                                <img width="106.46px" height=" 19.71px" alt="TrustPilot Rating" src="https://genki-preview.com/dynamic/trustpilot-stars.svg" />
                                <span>on</span>
                                <TrustPilotIcon/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Genki</title>
                <meta name="description" content="Genki the best nomad insurance, stay Genki" />
            </Helmet>
            <div className="layout">
                <div className="body-content-1">
                    <div className="body-main-content">
                        <div className="body-logo">
                            <GenkiLogo />
                        </div>
                        <div className="body-header-layout">
                            <h1 className="body-header-text">Health insurance for nomads</h1>
                            <div className="body-header-underline">
                                <GenkiUnderline />
                            </div>
                        </div>
                        <div className="body-subtext-layout">
                            <span className="body-subtext-1">Great benefits. Monthly subscription. Cancel anytime.</span>
                            <div className="body-subtext-button-layout">
                                <ButtonComponent label="Check your price" size="large" />
                            </div>
                        </div>
                    </div>
                    <div className="body-content-trustpilot-container">
                        <div className="trustpilot-icon">
                            <TrustPilotStarIcon />
                        </div>
                        <div className="body-content-trustpilot-container-inner ">
                            <span className="body-content-trustpilot-container-inner-text ">Travelers love us</span>
                            <div className="body-content-trustpilot-container-inner-ratings-container">
                                <img width="106.46px" height=" 19.71px" alt="TrustPilot Rating" src="https://genki-preview.com/dynamic/trustpilot-stars.svg" />
                                <span>on</span>
                                <TrustPilotStarIcon />
                                <span>Trustpilot</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="body-content-2">
                    <StaticImage alt="happy-nomad" src="../assets/images/HomePage.png" />
                </div>
                <div className="header-buttons">
                    <div className="header-width-buttons-sing-in">
                        <div className="header-button-group-div-start">
                            <ButtonComponent label="Sign in" variant="secondary" />
                        </div>
                    </div>
                    <div className="header-width-buttons-guide">
                        <div className="header-button-group-div-end">
                            <ButtonComponent iconPosition="end" icon={RightArrowIcon} label="Guide" variant="secondary" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LandingPage
