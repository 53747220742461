import React from "react"
import { Link } from "gatsby"
import "./index.scss"
interface ButtonProps {
    action?: string | (() => void)
    icon?: () => JSX.Element
    iconPosition?: "start" | "end"
    id?: string
    isClickableIfDisabled?: boolean
    isDisabled?: boolean
    size?: "small" | "medium" | "large"
    target?: string
    type?: "button" | "reset" | "submit"
    variant?: "primary" | "secondary"
    label?: string
}
const ButtonComponent = ({
    action,
    icon,
    iconPosition = "start",
    id,
    isClickableIfDisabled = false,
    isDisabled = false,
    size = "medium",
    target,
    type = "button",
    variant = "primary",
    label = "submit",
}: ButtonProps) => {
    let classes: string[] = ["button"]
    if (variant === "primary") {
        classes = [...classes, "button-primary"]
    } else {
        classes = [...classes, "button-secondary"]
    }
    switch (size) {
        case "small":
            classes = [...classes, "button-small"]
            break
        case "medium":
            classes = [...classes, "button-medium"]
            break
        case "large":
            classes = [...classes, "button-large"]
            break
        default:
            classes = [...classes, "button-medium"]
            break
    }
    if (typeof action === "string") {
        if (icon) {
            classes = [...classes, "button-icon"]
            if (iconPosition === "start") {
                return (
                    <Link id={id} className={classes.join(" ")} type={type} to={action} target={target}>
                        {icon()} {label}
                    </Link>
                )
            }
            return (
                <Link id={id} className={classes.join(" ")} type={type} to={action} target={target}>
                    {label} {icon()}
                </Link>
            )
        }
        return (
            <Link id={id} className={classes.join(" ")} type={type} to={action} target={target}>
                {label}
            </Link>
        )
    }
    if (icon) {
        classes = [...classes, "button-icon"]
        if (iconPosition === "start") {
            return (
                <button id={id} onClick={action} className={classes.join(" ")} type={type} disabled={isDisabled}>
                    {icon()} {label}
                </button>
            )
        }
        return (
            <button id={id} onClick={action} className={classes.join(" ")} type={type} disabled={isDisabled}>
                {label} {icon()}
            </button>
        )
    }
    return (
        <button id={id} onClick={action} className={classes.join(" ")} type={type} disabled={isDisabled}>
            {label}
        </button>
    )
}

export default ButtonComponent
